"use strict";

function ImgOptionField(opts) 
{
	this.options = {
		fieldSelector: null,
		optionSelector: '.img-option-field',
		activeClass: 'grayscale',
	};

	if(typeof opts === "object") {
		this.options = $.extend(this.options, opts);
	}


	this.bindEvents = function() {
		var controller = this;
		
		if(controller.options.fieldSelector != null) {

			$(controller.options.optionSelector).on('click', function(e) {
				e.preventDefault();

	            var value = $(this).data('value');

	            $(controller.options.optionSelector).addClass(controller.options.activeClass);
	            $(this).removeClass(controller.options.activeClass);

	            $(controller.options.fieldSelector).val(value);
			});


			$(controller.options.optionSelector + '-' + $(controller.options.fieldSelector).val()).removeClass(controller.options.activeClass);
		}
	}

	this.init = function() {
		var controller = this;
		controller.bindEvents();
	}
}
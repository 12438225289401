$( document ).ready(function() {

    if($(".sidebars").length != 0)
    {
        var sides = ["left", "top", "right", "bottom"];

        for (var i = 0; i < sides.length; ++i) {
            var cSide = sides[i];
            $(".sidebar." + cSide).sidebar({side: cSide});
        }

        $(".sidebar-btn[data-action]").on("click", function () {
            var $this = $(this);
            var action = $this.attr("data-action");
            var side = $this.attr("data-side");
            $(".sidebar." + side).trigger("sidebar:" + action);
            return false;
        });
    }

    $('#products-carousel').owlCarousel({
      nav: true,
      loop: true,
      dots: false,
      responsive :{
        0: {
          items: 1,
          dots: true,
          nav: false
        },
        480: {
          items: 2,
          dots: true,
          nav: false
        },
        768: {
          items: 3,
          dots: false,
          nav: true
        },
        1024: {
          items: 3
        }
      }
    });
    $( ".owl-prev").html('<i class="fas fa-chevron-left"></i>');
    $( ".owl-next").html('<i class="fas fa-chevron-right"></i>');

    $('#drop').click(function(){
      $('#drop-menu').slideToggle('medium', function(){

      });
    });

  $(".toggle-password-field").on('click', function(e) {

      e.preventDefault();

      var field = $(this).data('field');
      var current_text = $(this).html();
      var toggle_text = $(this).data('toggle_text');

      if($(field).attr('type') == 'text') {
          $(field).attr('type', 'password');
      } else {
          $(field).attr('type', 'text');
      }

      $(this).html(toggle_text);
      $(this).data('toggle_text', current_text);

  });

    $(window).scroll(function(){

      if($(this).scrollTop() > 63){
        $('.main').addClass('fadeIn fixed').css('z-index','1');
        $('.mobil').addClass('fixed');
      } else {
        $('.main').removeClass('fadeIn fixed').css('z-index','1');
        $('.mobil').removeClass('fixed');
      }

      if($(this).scrollTop() == 0) {
        $('.Header.home').addClass('cshow').removeClass('chide');
      }

      if($(this).scrollTop() > 63 ) {
        $('.Header.home').removeClass('cshow').addClass('chide');
      }

    });

   $(".img-detail-link").click( function(e) {
      e.preventDefault();
      var src = $(this).attr('data-src');
      $("#img-detail").attr('src',src);

      $(".img-zoom").attr('href',src);
      $("#img-detail").magnify();
   });

   $("#img-detail").magnify();

   $(".img-hover").hover(function(e) {
      if( e.type == "mouseenter" && $( this ).data("hover") !== undefined ) {
          $( this ).attr("src", $( this ).data("hover"));
      }

      if( e.type == "mouseleave" && $( this ).data("main") !== undefined ) {
          $( this ).attr("src", $( this ).data("main"));
      }
    });
});

"use strict";

function Uif(opts) 
{
	this.options = {
		formSelector: null,
	};

	if(typeof opts === "object") {
		this.options = $.extend(this.options, opts);
	}


	this.bindEvents = function() {
		var controller = this;
		
		if(controller.options.formSelector != null) {

			var $container = $(controller.options.formSelector);


			$container.find('input[type=number]').on('change', function(e) {
				e.preventDefault();
				$container[0].submit();
			});
		}
	}

	this.init = function() {
		var controller = this;
		controller.bindEvents();
	}
}
"use strict";

function Pcw(opts) 
{
	this.options = {
		formSelector: '#add-to-cart-form',
		configFieldSelector: '.product_config_id',
		configNotFoundSelector: '.config_not_found',
		configs: [],
		allowBaseProducts: true
	};

	if(typeof opts === "object") {
		this.options = $.extend(this.options, opts);
	}


	this.getDefaultConfig = function() {
		var controller = this;
		if(controller.options.configs.length != 0){
			var first = Object.keys(controller.options.configs)[0];
			return controller.options.configs[first];
		}

		return false;
	}


	this.setConfig = function(config) {
		var controller = this;
		var $container = $(controller.options.formSelector);

		if(config !== false) {
			$(controller.options.configFieldSelector).val(config.id);
			$.each(config.config, function(i,val) {
				$(controller.options.formSelector).find('.option-field-' + i).val(val);
			});

			if(controller.options.allowBaseProducts == false) {
				$container.find('button[type=submit]').removeAttr('disabled');
				$container.find(controller.options.configNotFoundSelector).html('');
			}

		} else {
			controller.configNotFound();
		}
	}

	this.configNotFound = function() {
		var controller = this;
		var $container = $(controller.options.formSelector);

		if(controller.options.allowBaseProducts == false) {
			$container.find('button[type=submit]').attr('disabled', true);
			$container.find(controller.options.configNotFoundSelector).html('<p class="notify warning mt-3 p-0"><small>No existe la configuración seleccionada.</small></p>');
		}
	}

	this.bindEvents = function() {
		var controller = this;
		var $container = $(controller.options.formSelector);

		$container.find('.option-field').change( function(e) {

			var selection = {};
			$.each($container.find('.option-field'), function(i, o) {
				var option_id = $(o).data('option');
				var value = $(o).val();
				selection[option_id] = value;
			});

			var found = false;
			$.each(controller.options.configs, function(i,o) {
				if(JSON.stringify(o.config) === JSON.stringify(selection)) {
					found = o;
					return;	
				}
			});

			controller.setConfig(found);
		});
	}

	this.init = function() {
		var controller = this;
		controller.bindEvents();
		controller.setConfig( controller.getDefaultConfig() );
	}
}